<template>
  <b-card title="Events Table">
    <div
      class="
        custom-search
        d-flex
        justify-content-end justify-content-sm-start
        mb-2 mb-sm-0
      "
    >
      <div class="d-flex align-items-center">
        <b-button
          id="show-btn"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="float-right"
          variant="primary"
          :to="{ name: 'create-event' }"
          >Add event</b-button
        >
      </div>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="params.query"
            placeholder="Search"
            type="text"
            class="d-inline-block"
            debounce="1500"
          />
        </div>
      </b-form-group>
    </div>
    <Modal
      :text="modalOptions.text"
      :ok-button="modalOptions.okButton"
      :cancel-button="modalOptions.cancelButton"
      :show="show"
      @result="modalOptions.modalResult"
      @hidden="show = false"
    />
    <!-- table -->
    <vue-good-table
      mode="remote"
      :row-style-class="rowStyleClassFn"
      :pagination-options="{
        enabled: true,
      }"
      :totalRows="totalRecords"
      :rows="rows"
      :columns="columns"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-per-page-change="onPerPageChange"
      :isLoading.sync="isLoading"
    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                :to="{
                  name: 'update-event',
                  params: { id: props.row._id },
                }"
              >
                <span
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                >
                  Edit
                </span>
              </b-dropdown-item>
              <b-dropdown-item @click="showRemovePopup(props.row)">
                <span
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                >
                  Delete
                </span>
              </b-dropdown-item>
              <b-dropdown-item @click="copyURL(props.row.eventLink)">
                <span
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                >
                  Copy in-app link
                </span>
              </b-dropdown-item>
              <b-dropdown-item @click="showConfirmationModal(props.row)">
                <span>{{
                  !props.row.isUpcoming
                    ? "make event upcoming"
                    : "remove event upcoming"
                }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <span v-if="props.column.field == 'Media'" class="d-flex">
          <b-avatar
            class="m-auto"
            size="40"
            :src="props.row.media.path"
            style="background-color: white"
          />
        </span>

        <span v-if="props.column.field == 'Description'" class="d-flex">
          <label class="ml-1" v-html="props.row.description"></label>
        </span>

        <span v-if="props.column.field == 'EventHost'" class="d-flex">
          {{
            props.row.eventHost ? props.row.eventHost : props.row.createdBy.name
          }}
        </span>

        <span v-if="props.column.field == 'Type'" class="d-flex">
          {{ props.row.createdByAdmin ? props.row.eventHost : props.row.type }}
        </span>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template #emptystate>
        <p class="text-center">
          {{
            isOPen ? "Loading..." : "This will show up when there are no rows"
          }}
        </p>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BCard,
  BButton,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import Prism from "vue-prism-component";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import EventService from "@/services/EventService";
import Modal from "@/views/components/shared/Modal/Modal";

export default {
  name: "AdminList",
  mixins: [],
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    BDropdownItem,
    BDropdown,
    BCard,
    BButton,
    Modal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      params: {
        page: 1,
        limit: 10,
        sort_keys: undefined,
        sort_dir: undefined,
        query: undefined,
      },
      columns: [
        { label: "Title", field: "title" },
        { label: "Event Host", field: "EventHost" },
        { label: "From date", field: "date" },
        { label: "To date", field: "toDate" },
        { label: "From", field: "from" },
        { label: "To", field: "to" },
        { label: "Description", field: "Description" },
        { label: "Location", field: "location" },
        { label: "External link", field: "externalLink" },
        { label: "Type", field: "Type" },
        { label: "Cover photo", field: "Media", sortable: false },
        { label: "Action", field: "action" },
      ],
      rows: [],
      isLoading: undefined,
      isOPen: true,
      totalRecords: undefined,
      eventService: new EventService(),
      modalOptions: {
        modalResult: this.modalResultRemove,
      },
      show: false,
    };
  },
  watch: {
    params: {
      handler: function () {
        this.getEvent();
      },
      deep: true,
    },
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true;
        return this.dir;
      }
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    this.getEvent();
  },
  methods: {
    onPerPageChange(page) {
      this.params.page = 1;
      this.params.limit = page.currentPerPage;
    },
    onPageChange(active) {
      this.params.page = active.currentPage;
      this.params.limit = active.currentPerPage;
    },
    onSortChange(params) {
      const SortKey =
        typeof params[0].field === "function" ? "client" : params[0].field;
      this.params.sort_keys = [SortKey[0].toLowerCase() + SortKey.slice(1)];
      this.params.sort_dir = [params[0].type];
    },
    modalResultRemove(value) {
      this.show = false;
      if (value) {
        this.deleteEvent(this.model._id);
      }
    },
    rowStyleClassFn(row) {
      if (row.isUpcoming == true) return "first";
    },
    showRemovePopup(model) {
      this.show = true;
      this.model = model;
      this.modalOptions = {
        text: `Are you sure you want to delete this event ?`,
        okButton: `Delete`,
        cancelButton: "Cancel",
        clickedId: model._id,
        currentAction: "Block",
        modalResult: this.modalResultRemove,
      };
    },

    deleteEvent(id) {
      this.isLoading = true;
      this.eventService
        .delete(id)
        .then((res) => {
          this.$router.go();
        })
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    showConfirmationModal(model) {
      this.show = true;
      this.model = model;
      this.modalOptions = {
        text: `Are you sure you want to ${
          !model.isUpcoming ? "make" : "remove"
        } this event upcoming ?`,
        okButton: `${!model.isUpcoming ? "make" : "remove"}`,
        cancelButton: "Cancel",
        clickedId: model._id,
        currentAction: "Block",
        modalResult: this.modalResultOfUpcoming,
      };
    },
    modalResultOfUpcoming(value) {
      this.show = false;
      if (value) {
        this.setEventUpcoming(this.model._id, this.model.isUpcoming);
      }
    },
    setEventUpcoming(id, isUpcoming) {
      this.eventService
        .makeEventUpcoming({ id, isUpcoming })
        .then((response) => {
          this.$router.go();
        })
        .catch((err) => {});
    },
    getEvent() {
      this.isLoading = true;
      this.eventService
        .index(this.params)
        .then((res) => {
          this.rows = res.data.map((el) => {
            return {
              ...el,
              date: new Date(el.date).toLocaleDateString(),
              toDate: el.toDate ? new Date(el.toDate).toLocaleDateString() : '',
            };
          });
          this.totalRecords = res.count;
        })
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
          this.isOPen = false;
        });
    },
    async copyURL(mytext) {
      try {
        window.Clipboard = (function (window, document, navigator) {
          var textArea, copy;

          function isOS() {
            return navigator.userAgent.match(/ipad|iphone/i);
          }

          function createTextArea(text) {
            textArea = document.createElement("textArea");
            textArea.value = text;
            document.body.appendChild(textArea);
          }

          function selectText() {
            var range, selection;

            if (isOS()) {
              range = document.createRange();
              range.selectNodeContents(textArea);
              selection = window.getSelection();
              selection.removeAllRanges();
              selection.addRange(range);
              textArea.setSelectionRange(0, 999999);
            } else {
              textArea.select();
            }
          }

          function copyToClipboard() {
            document.execCommand("copy");
            document.body.removeChild(textArea);
          }

          copy = function (text) {
            createTextArea(text);
            selectText();
            copyToClipboard();
          };

          return {
            copy: copy,
          };
        })(window, document, navigator);

        Clipboard.copy(mytext);
        alert("Copied");
      } catch ($e) {
        alert("Cannot copy");
      }
    },
  },
};
</script>
<style>
.first {
  background-color: #fce19eae;
}

.loader-overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: wait;
}

@media (min-width: 576px) {
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
}
@media (max-width: 576px) {
  th,
  td {
    /* font size */
    font-size: 0.857rem;
    font-weight: 400;
    /* text center horizontally */
    vertical-align: middle !important;
    text-align: center;
    line-height: 20px;
  }
}
</style>