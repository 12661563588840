<template>
  <div class="">
    <EventList/>
  </div>
</template>

<script>
import EventList from "@/views/components/event/EventList";
import GoodTableSsr from "@/views/table/vue-good-table/GoodTableSsr";
import '@core/scss/vue/libs/vue-good-table.scss';
export default {
  name: "Index",
  components: {
    EventList,
    GoodTableSsr
  }
}
</script>

<style scoped lang="scss">

</style>
